import React, { Component } from 'react';

export class Skills extends Component {

    render(){
        return (
            <div className='hero is-success'>
                <div className='hero-body has-text-black has-text-centered has-text-weight-semibold'>
                    <i class="fas fa-code"></i>  
                    JavaScript (ReactJS), Java, SQL, Python (pandas, numpy, sci-kit learn, PyTorch, Tensorflow), C#, .NET, Scala, Tableau, Hadoop, AWS   
                    <i class="fas fa-code"></i>
                </div>
            </div>
        );
    }
}

export default Skills; 