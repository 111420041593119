export const books = [
    {
        'number':0, 
        'title':"Educated: A Memoir", 
        'author':'Tara Westover', 
        'rating':5, 
        'yearread':2018, 
        'review':'Westover is heartbreakingly honest about her upbringing, the relationships with her family members, and the struggle of leaving everything you know for everything you want. Raw and inspirational, her story sticks long after the last page.'
    },
    {
        'number':1, 
        'title':"Fluent Forever: How to Learn Any Language Fast and Never Forget It", 
        'author': 'Gabriel Wyner', 
        'rating':5, 
        'yearread':2018, 
        'review':'Engineer turned language expert, Gabriel\'s passion for language acquisition is tangible throughout this entire book. Since publication, Wyner has launched the Fluent Forever app, which I am using to learn French.'
    }, 
    {
        'number':2,
        'title':'The Other Woman: A Novel', 
        'author':'Daniel Silva',
        'rating':4, 
        'yearread':2018, 
        'review':'This thrilling spy novel is one in a series about Gabriel Allon, a highly reputed intelligence agent for Tel Aviv. Though this novel was riddled with suspense and chills, I went back to read the first in the series and found it fell flat.'
    }, 
    {
        'number':3, 
        'title':'The Rooster Bar', 
        'author':'John Grisham', 
        'rating':4, 
        'yearread':2018, 
        'review':'I adored Grisham\'s story about an elaborate scheme brainstormed by vigilante college students - a fun and easy summer read. I absolutely burned through this book.'
    }, 
    {
        'number':4, 
        'title':'The Heart\'s Invisible Furies: A Novel', 
        'author':'John Boyne', 
        'rating':4, 
        'yearread':2018, 
        'review':'Boyne tracks a young Irishman struggling with his sexuality as he ages, immigrating to the United States, and dealing with his past traumas as he ages. Lengthy but beautiful, would recommend.'
    }, 
    {
        'number':5, 
        'title':'Crazy Rich Asians', 
        'author':'Kevin Kwan', 
        'rating':4, 
        'yearread':2018, 
        'review':'Kwan details the elaborate class and social system that intersects these families in beautiful weavings between characters, emotions, and ideals.'
    }, 
    {
        'number':6, 
        'title':'Eleanor Oliphant Is Completely Fine: A Novel', 
        'author':'Gail Honeyman', 
        'rating':4, 
        'yearread':2018, 
        'review':'Oliphant is a character unlike any that I have read in the past. Honeyman\'s unique telling of her story kept this book in my hands from start to finish.'
    }, 
    {
        'number':7, 
        'title':'Becoming', 
        'author':'Michelle Obama', 
        'rating':5, 
        'yearread':2019, 
        'review':'Obama writes this book as though she is talking to me at her kitchen table. She is frank, and frankly, hilarious throughout.'
    }, 
    {
        'number':8, 
        'title':'Competing on Analytics: Updated', 
        'author': 'Jeanne G. Harris', 
        'rating':3, 
        'yearread':2019, 
        'review':'This book gave valuable insight toward the business motivations for a robust analytics team, which I had not covered in school.'
    }, 
    {
        'number':9, 
        'title':'Where the Crawdads Sing', 
        'author':'Delia Owens', 
        'rating':5, 
        'yearread':2019, 
        'review':'Owens tells a unique story, demonstrating careful character development throughout. Kya\'s story interlaced with a murder mystery kept me thinking about this book every time I had to put it down.'
    }, 
    {
        'number':10, 
        'title':'The Guest Book: A Novel', 
        'author':'Sarah Blake', 
        'rating':5, 
        'yearread':2019, 
        'review':'This book reminded me of the Great Gatsby in social commentary distributed through the characters of a multi-generational family. One of my favorite reads of the summer - by far.'
    }, 
    {
        'number':11, 
        'title':'Before We Were Yours: A Novel', 
        'author':'Lisa Wingate', 
        'rating':5, 
        'yearread':2019, 
        'review':'A heart-wrenching and sickening historical fiction. I was compelled to research the history of for-profit adoption due to Wingate\'s torturously vivid description.'
    }, 
    {
        'number':12, 
        'title':'Go Wild: Free Your Body and Mind from the Afflictions of Civilization', 
        'author': 'John J. Ratey', 
        'rating':5, 
        'yearread':2019, 
        'review': 'I have been a fan of Dr. Ratey for years, but his holistic approach to well-being makes this book essentially a health bible.'
    }, 
    {
        'number':13, 
        'title':'The Kill Artist', 
        'author':'Daniel Silva', 
        'rating':2, 
        'yearread':2019, 
        'review': 'Meh.'

    }, 
    {
        'number':14, 
        'title':'Eat Pray Love', 
        'author':'Elizabeth Gilbert', 
        'rating':4, 
        'yearread':2020, 
        'review':'This plane-ride read enriched my travel through Ireland, encouraging me to fully enjoy and be present in the trip from beginning to end, very similar to how I felt reading this book.'
    }, 
    {
        'number':15, 
        'title':'East of Eden', 
        'author':'John Steinbeck', 
        'rating':4, 
        'yearread':2020, 
        'review':'Like other Steinbeck works, it seems as though nothing super-important happens until the end. However, Steinbeck writes beautifully enough to convince me to read this incredibly long novel through.'
    }, 
    {
        'number':16,
        'title':'Little Fires Everywhere: A Novel',
        'author':'Celeste Ng', 
        'rating':5, 
        'yearread':2020, 
        'review':'Celeste tells a complicated story about wealth, families, coming-of-age with the artistry of one of her main characters.'
    }, 
    {
        'number':17, 
        'title': 'Everything I Never Told You: A Novel', 
        'author':'Celeste Ng', 
        'rating':5, 
        'yearread':2020, 
        'review':'My favorite part about this book was how each character\'s history explained their behaviors and flaws, complicating each line of the story and the emotions I felt while plunging through it.'
    }
];