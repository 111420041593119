export const experience = [
    {
      'company':'Endurance International Group', 
      'dates':'January 2019-Present', 
      'accomplishments':[
        {'role':0, 'accomplishment':'Recommends spending strategy for over $50,000 of discretionary channel spend via comprehensive return on advertisement spend analysis by channel using Google Analytics web session and internal customer data'},
        {'role':0, 'accomplishment':'Developed and automated scoring and delivery of recent customers exhibiting low engagement to the customer success team for proactive reachout program that drove 37% increase in early engagement'},
        {'role':0, 'accomplishment':'Deliver recurring historical monthly lifetime revenue report for all Bluehost customers to monitor changes in customer behavior over time'}, 
        {'role':0, 'accomplishment':'Conducted co-affinity analysis in Python to add in-cart recommendations on the Domain.com brand and increase cross-sell opportunities for existing customers'},
        {'role':0, 'accomplishment':'Matured retention and renewal revenue forecasting by incorporting Kaplan Meier survival analysis'},
        {'role':1, 'accomplishment':'Productionalize models and analyses in Hadoop, AWS, and Snowflake for myself and five co-workers to increase productivity across the team'},
        {'role':1, 'accomplishment':'Segmented customers by domain name to identify site purpose and predict future behavior at sign up time'},
        {'role':1, 'accomplishment':'Created internal campaign evaluation tool to quickly and easily evaluate the impact of marketing email campaigns based on the desired sucess metric of the marketing team'},
        {'role':2, 'accomplishment':'Roadmap, manage, and report the customer analytics focus for Domain.com to increase the analytical power of the brand and inform the senior leadership team of progress and next steps'},
        {'role':2, 'accomplishment':'Design experimentation strategy and implement rigorous evaluation standards for product and marketing testing on the Bluehost brand for consistent, statistially significant, and accurate testing across strata'},
        {'role':2, 'accomplishment':'Promote data accessibility and transparency across the company via the creation of dashboards and datasets in Tableau'}
      ]
    }, 
    {
      'company':'Office Depot', 
      'dates':'May 2018-August 2018', 
      'accomplishments':[
        {'role': 0, 'accomplishment':'Constructed a neural network to predict supply chain break points during the order lifecycle, resulting in more fullfilled orders'}, 
        {'role': 0, 'accomplishment':'Diagnosed poor user experience in coupon error messaging via analyzing web session data resulting in increased messaging and increased coupon usage'},
        {'role':1, 'accomplishment':'Performed complex ETL transformations ingesting data from Teradata Snowflake, and other warehousing systems to be standardized and used as input for Tensorflow model'},
        {'role':1, 'accomplishment':'Practiced agile methodology by participating in daily standups, sprint refinement and planning meeetings with functional team'},
        {'role':2, 'accomplishment':'Presented analyses and recommendations to senior leadership teams with nontechnical backgrounds in digestible formats such as slide decks and infographics'},
        {'role':2, 'accomplishment':'Worked in a cross functional team which was tasked with development, analytics, launch, and promotion of the new mobile app which resulted in increased ratings in the App Store to 4.7 out of 5 stars, increased mobile app sales and personalized coupon usage'}
      ]
    },
    {
      'company':'Oceanos Inc.', 
      'dates':'May 2017-January 2018', 
      'accomplishments':[
        {'role':0, 'accomplishment':'Created trustworthiness score for all contact data records in our database to increase hit rates for our clients'}, 
        {'role':0, 'accomplishment':'Automated business health reporting to save developer time and increase CEO\'s visibility into the business'},
        {'role':1, 'accomplishment':'Developed an automated data ingestion, clean, and search tool with UI for internal website which reduced time for sales team to onboard a new client by 50%'},
        {'role':1, 'accomplishment':'Enhanced search capabilities for contact search to accomodate more detailed customer requests.'},
        {'role':1, 'accomplishment':'Updated internal website with new features and user experience tools to increase productivity for sales team'},
        {'role':2, 'accomplishment':'Documented code and functionality to support future developers.'},
        {'role':2, 'accomplishment':'Interpreted the needs of the sales team to roadmap feature expansion across our internal sales platform'},
        {'role':2, 'accomplishment':'Presented findings and finished projects to multiple teams, including the CEO.'},
        {'role':2, 'accomplishment':'Hired as a remote team member after internship completion for continued development prior to company acquisition by TechTarget'}
      ]
    }
  ];